<template>
    <div>
        <kendo-grid ref="grid"
                    :data-source="dataSource"
                    :pageable="true"
                    :filterable="true"
                    :sortable="true"
                    :columns="columns"
                    v-on:detailinit="detailInit"
                    v-on:databound="dataBound"
                    :resizable="true">
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable.js';
import roleService from '../Script/RoleServices.js';

export default {
    name: 'RoleGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                        page: 1,
                        pageSize: kendo_grid.default_grid_pagesize,
                        serverPaging: false,
                        transport: {
                            read: {
                                contentType: "application/json",
                                url: apiBaseUrl,
                                type: "POST",
                                data: function() {
                                    return { query: roleService.readQuery() };
                                },
                                beforeSend: function (req) {
                                    req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                    req.setRequestHeader('Path', window.location.pathname);
                                }
                            },
                            parameterMap: function(options, operation) {
                                return  kendo.stringify({
                                    query: options.query,
                                    variables: options.variables
                                });
                            }
                        },
                        schema: {
                            data: function(response) {
                                if(response.data.GetRole == null){
                                    return [];
                                }else{
                                    return response.data.GetRole;
                                }
                            },
                            total: function(response) {
                                if(response.data.GetRole == null){
                                    return 0;
                                }else{
                                    return response.data.GetRole.length;
                                }
                            }
                        },
                }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "rname", title: "Role Name", headerAttributes:{ style: "text-align:center; font-weight:bold;" } }
            ],
        }
    },
    mounted: function(){
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem);
        })
        
        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.roleid);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Role")
        },
        detailInit: function (e) {
            $('<div />').appendTo(e.detailCell).kendoGrid({
                ref: "permissionGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.role_access);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                                    query: options.query,
                                    variables: options.variables
                                });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        },
                        model: {
                            id: "moduleid",
                            fields: {
                                mname: { type: "string", editable: false },
                                rperms: { type: "boolean" },
                                cperms: { type: "boolean" },
                                uperms: { type: "boolean" },
                                dperms: { type: "boolean"}
                            }
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'mname', title: "Module Name", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'rperms', title: "Read", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                    { field: 'cperms', title: "Create", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                    { field: 'uperms', title: "Update", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                    { field: 'dperms', title: "Delete", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                ],
            })
        },
        dataBound: function (ev) {
            // ev.sender.expandRow(ev.sender.tbody.find('tr.k-master-row').first())
        },
    },
}

    
</script>

<style scoped>
</style>