<template>
    <CRow class="p-3">
        <div class="col-xs-6 text-light pb-3">
            <button id="add" class="btn btn-success"
                @click="addClick()">
                <i class="fa fa-plus"></i> Add
            </button>
        </div>

        <datasource ref="regionDataSource" :data="this.RegionGridData" :page-size="10" :schema-model-fields="this.RegionGridDataSchemaModel"/>

        <kendo-grid ref="grid"
            :data-source-ref="'regionDataSource'"
            :editable="true"
            :pageable="true"
            :resizable="true"
            >
            
            <kendo-grid-column  :field="'region_id'"
                                :title="'Id'"
                                :width="200"
                                :hidden = "true"
                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

            <kendo-grid-column  :field="'region_code'"
                                :title="'Kode'"
                                :width="200"
                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

            <kendo-grid-column  :field="'region_name'"
                                :title="'Nama'"
                                :width="200"
                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

            <kendo-grid-column  :title="'&nbsp;'"
                                :attributes="{ class: 'k-text-center' }"
                                :command="['destroy']"
                                :width="200"
                                ></kendo-grid-column>

        </kendo-grid>
        <region-form ref="regionForm" :saveRegionDataGrid="saveRegionDataGrid"/>
    </CRow> 
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import regionForm from '../Component/RegionForm.vue';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import RoleServices from '../Script/RoleServices.js';

export default {
    name: 'RegionGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'region-form' : regionForm,
        'datasource' : DataSource,
    },
    props: ['RoleId'],
    data(){
        var roleid = this.$props.RoleId
        return{
            ModalTitle: '',
            roleid: roleid,
            RegionData:[],
            RegionGridData: [],
            RegionGridDataSchemaModel: {
                region_code : {type: "string", editable: false},
                region_name : {type: "string", editable: false},
            },
        }
    },
    methods: {
        async regionGridReload(roleid){
            var data = await RoleServices.getRoleRegion(roleid);  
            this.RegionGridData = globalfunc.objectToArrayConverter(data.GetRole[0].role_region, "RoleRegion-Data");    
        },
        addClick(){
            this.$refs.regionForm.addClick();
        },
        saveRegionClick(roleid){
            this.RegionData = this.$refs.grid.kendoWidget().dataSource._data;

            var RegionArray = [];
            if (this.RegionData.length > 0) {
                for (let i = 0; i < this.RegionData.length; i++) {
                    var str = { 
                        roleid: roleid,
                        region_id: this.RegionData[i].region_id
                    }
                    RegionArray.push(str)
                }                
            }

            const variables = {
                id : roleid,
                data : RegionArray
            }
        
            RoleServices.updateRoleRegion(variables);
        },
        saveRegionDataGrid(data){
            var gridData = this.$refs.grid.kendoWidget().dataSource._data;
            var ItemData = globalfunc.objectToArrayConverter(gridData, "RoleRegion-Data");
            ItemData = ItemData.concat(data);
            this.RegionGridData = Object.values(ItemData.reduce((a, c) => (a[`${c.region_id}${c.region_code}${c.region_name}`] = c, a), {}));        
        },
    },
}

</script>

<style scoped>
</style>