<template>
    <CRow class="p-3">
        <kendo-grid ref="grid"
                    :data-source="flagData"
                    :pageable="true"
                    :filterable="true"
                    :sortable="true"
                    :scrollable="true"
                    :columns=columns
                    :editable="true"
                    :resizable="true">
        </kendo-grid>
    </CRow> 
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import roleService from '../Script/RoleServices.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'FlagGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['RoleId', 'editClick'],
    computed: {
        flagData() {
            var roleId = this.$props.RoleId;
            if(roleId == null || roleId.toString() == ''){
                roleId = -1;
            }
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function() {
                            return { 
                                query: roleService.getRoleFlag(),
                                variables: {"id": roleId}
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    }
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetRole == null){
                            return [];
                        }else{
                            return response.data.GetRole[0].role_flag;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetRole == null){
                            return 0;
                        }else{
                            return response.data.GetRole[0].role_flag.length;
                        }
                        
                    },
                    model: {
                        id: "roleid",
                        fields: {
                            roleid: {type: "number", editable: false },
                            flag_name: {type: "string", editable: false },
                            flag_status: { type: "boolean" }
                        }
                    }
                },
            })
        }
    },
    data(){
        return{
            FlagData: [],
            columns: [                
                { field: 'flag_name', title: "Nama Flag", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                { field: 'flag_status', title: "Status Flag", width: 200, 
                    headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    attributes: { "class": "k-text-center" },
                    template: '<input type="checkbox" #= flag_status ? \'checked="checked"\' : "" # class="k-checkbox k-checkbox-md k-rounded-md" />'                
                },
            ],
        }
    },
    methods: {
        saveFlagClick(roleid){
            this.FlagData = this.$refs.grid.kendoWidget().dataSource._data;
            if (this.FlagData.length > 0) {
                var FlagArray = [];
                for (let i = 0; i < this.FlagData.length; i++) {
                    var detail = { 
                        roleid: roleid,
                        flag_id: this.FlagData[i].flag_id,
                        flag_status: this.FlagData[i].flag_status
                    }
                    FlagArray.push(detail)
                }
                
                const variables = {
                    id : roleid,
                    data : FlagArray
                }

                roleService.updateRoleFlag(variables);
            }
        },
    },
}

</script>

<style scoped>
</style>