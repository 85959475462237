<template>
    <CRow class="p-3">
        <kendo-grid ref="grid"
                    :data-source="ddlData"
                    :pageable="true"
                    :filterable="true"
                    :sortable="true"
                    :scrollable="true"
                    :columns=columns
                    :editable="true"
                    :resizable="true">
        </kendo-grid>
    </CRow> 
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import roleService from '../Script/RoleServices.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'DropdownListGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['RoleId'],
    computed: {
        ddlData() {
            var roleId = this.$props.RoleId;
            if(roleId == null || roleId.toString() == ''){
                roleId = -1;
            }
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function() {
                            return { 
                                query: roleService.getRoleDdl(),
                                variables: {"id": roleId}
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    update: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(model) {
                            var data = { 
                                ddl: model.ddl,
                                ddlname: model.ddlname,
                                isallowed: model.isallowed,
                                role_id: model.roleid
                            }
                            return {
                                query: roleService.updateDdl(),
                                variables: {"ddlAccess": [data]}
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    }
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetRole == null){
                            return [];
                        }else{
                            return response.data.GetRole[0].ddl_access;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetRole == null){
                            return 0;
                        }else{
                            return response.data.GetRole[0].ddl_access.length;
                        }
                    },
                    model: {
                        id: "roleid",
                        fields: {
                            roleid: {type: "number", editable: false },
                            ddlname: {type: "string", editable: false },
                            ddl: { type: "string", editable: false },
                            isallowed: { type: "boolean" }
                        }
                    }
                },
            })
        }
    },
    data(){
        return{
            columns: [                
                { field: 'ddlname', title: "Type", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                { field: 'ddl', title: "Value", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                { field: 'isallowed', title: "Is Allowed", width: 200, 
                    headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    attributes: { "class": "k-text-center" },
                    template: '<input type="checkbox" #= isallowed ? \'checked="checked"\' : "" # class="k-checkbox k-checkbox-md k-rounded-md" />'
                },
            ],
        }
    },
    methods: {
        saveDropdownListClick(id){
            if(id != 0){
                var grid = this.$refs.grid.kendoWidget();
                grid.saveChanges();
            }
        },
    },
}

</script>

<style scoped>
</style>