<template>
    <div>
        <input hidden type="text" class="form-control" id="RolerId" v-model="RoleId" >
        <div class="modal fade" id="roleModal" tabindex="-1" aria-labelledby="roleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="roleModalLabel" class="font-weight-bold">Manage Role</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CTabs>
                            <CTab title="Role" id="tab-1" active> 
                                <CRow class="pt-3">
                                    <CCol sm="2">
                                        <label class="form-label font-weight-bold required">Role Name</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol sm="4">
                                        <CInput id="Rolename" v-model="Rolename" class="font-weight-bold" />
                                        <label id="errorRolename" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab title="Permission" id="tab-2">
                                <permission-grid ref="permissionGrid" :key="PermissionGridReload" :RoleId="this.RoleId" :checkClick ="this.checkClick"/>
                            </CTab>
                            <CTab title="Dropdown List" id="tab-3">
                                <dropdown-list-grid ref="dropdownListGrid" :key="DropdownListGridReload" :RoleId="this.RoleId"/>
                            </CTab>
                            <CTab title="Dashboard" id="tab-4">
                                <dashboard-grid ref="dashboardGrid" :key="DashboardGridReload" :RoleId="this.RoleId"/>
                            </CTab>
                            <CTab title="Flag" id="tab-5">
                                <flag-grid ref="flagGrid" :key="FlagGridReload" :RoleId="this.RoleId"/>
                            </CTab>
                            <CTab title="Region" id="tab-6">
                                <region-grid ref="regionGrid" :key="RegionGridReload" :RoleId="this.RoleId"/>
                            </CTab>
                        </CTabs>
                        <br>
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import roleServices from '../Script/RoleServices.js';
import permissionGrid from '../Grid/PermissionGrid.vue';
import dropdownListGrid from '../Grid/DropdownListGrid.vue';
import dashboardGrid from '../Grid/DashboardGrid.vue';
import flagGrid from '../Grid/FlagGrid.vue';
import regionGrid from '../Grid/RegionGrid.vue';

import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'RoleForm',
    props: ['reload'],
    components: {
        'permission-grid': permissionGrid,
        'dropdown-list-grid': dropdownListGrid,
        'dashboard-grid': dashboardGrid,
        'flag-grid': flagGrid,
        'region-grid': regionGrid
    },
    data: function () {
        return {
            RoleId: '',
            Rolename: '',
            ViewAll: '',
            PermissionGridReload: 0,
            DropdownListGridReload: 0,
            DashboardGridReload: 0,
            FlagGridReload: 0,
            RegionGridReload: 0
        }
    },
    methods: {
        addClick(){            
            this.RoleId = '';
            this.Rolename = '';
            this.SaveType = 'Add';

            //Auto Click Tab and Hide Other Tab
            $('#tab-1').trigger('click');
            $('#tab-2').hide();
            $('#tab-3').hide();
            $('#tab-4').hide();
            $('#tab-5').hide();
            $('#tab-6').hide();

            window.$('#roleModal').modal('show');
        },
        editClick(roleData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();

            this.SaveType = 'Edit';

            this.RoleId = roleData.roleid;
            this.Rolename = roleData.rname;
            this.$cookies.set('RoleId', roleData.roleid,new Date(roleData.roleid.expired_at*1000));
            
            this.FlagGridReload++;
            this.PermissionGridReload++;
            this.DropdownListGridReload++;

            this.$refs.dashboardGrid.dashboardGridReload(this.RoleId);
            this.$refs.regionGrid.regionGridReload(this.RoleId);

            //Auto Click Tab and Show Other Tab
            $('#tab-1').trigger('click');
            $('#tab-2').show();
            $('#tab-3').show();
            $('#tab-4').show();
            $('#tab-5').show();
            $('#tab-6').show();
            
            if(View){
                $(".save-button").hide();
            }

            window.$('#roleModal').modal('show');
        },
        checkClick (permissionData){
            const data = {
                roleid: permissionData.roleid,
                moduleid: permissionData.moduleid,
                rperms: permissionData.rperms,
                cperms: permissionData.cperms,
                uperms: permissionData.uperms,
                dperms: permissionData.dperms
            }
            const variables = {
                data : data
            }
            roleServices.updatePerm(variables)
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.Rolename == ''){
                this.errorShow('errorRolename')
            }
        },
        saveClick() {
            this.inputValidation();
            
            if(this.Error == 0){
                this.$loading(true);
                //Add
                if(this.SaveType == 'Add'){
                    const variables = {
                        data : this.Rolename
                    };

                    roleServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#roleModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    var roleId = parseInt(this.RoleId);

                    const variables = {
                        id : roleId,
                        data : this.Rolename
                    }

                    try{
                        //update Permission
                        this.$refs.permissionGrid.savePermissionClick(roleId);

                        //update Dropdown List
                        this.$refs.dropdownListGrid.saveDropdownListClick(roleId);

                        //update DashBoard 
                        this.$refs.dashboardGrid.saveDashboardClick(roleId);

                        //update Flag 
                        this.$refs.flagGrid.saveFlagClick(roleId);

                        // update Region 
                        this.$refs.regionGrid.saveRegionClick(roleId);

                        //update Role Data
                        roleServices.editQuery(variables);

                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#roleModal').modal('hide');
                        this.$props.reload();
                    }
                    catch(error){
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                    }
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    },
    provide() {
        return Object.defineProperty({}, 'RoleIdinject', {
            get: () => this.RoleId
        })
    },
}
</script>
