<template>
    <CRow class="p-3">
        <kendo-grid ref="grid"
                    :data-source="dataSource"
                    :pageable="true"
                    :filterable="true"
                    :sortable="true"
                    :columns=columns
                    :editable="true"
                    :resizable="true">
        </kendo-grid>
    </CRow> 
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import roleService from '../Script/RoleServices.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PermissionGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['RoleId','checkClick'],
    data(){
        var roleId = this.$props.RoleId;
        if(roleId == null || roleId.toString() == ''){
            roleId = -1;
        }
        return{
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function() {
                            return { 
                                query: roleService.readPermQuery(),
                                variables: {"id": roleId}
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    update: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(model) {
                            delete model['rname']
                            delete model['mname']
                            delete model['category']
                            delete model['__ob__']
                            return {
                                query: roleService.updatePermission(),
                                variables: {"permission": [model]}
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    }
                },
                schema: {
                    data: function(response) {
                        if (response.data.GetRole == null) {
                            return [];
                        }
                        else {
                            return response.data.GetRole[0].role_access;
                        }
                    },
                    total: function(response) {
                        if (response.data.GetRole == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetRole[0].role_access.length;
                        }
                    },
                    model: {
                        id: "moduleid",
                        fields: {
                            roleid: {type: "number", editable: false },
                            mname: { type: "string", editable: false },
                            rperms: { type: "boolean" },
                            cperms: { type: "boolean" },
                            uperms: { type: "boolean" },
                            dperms: { type: "boolean" }
                        }
                    }
                },
            }),
            columns: [
                { field: 'mname', title: "Module Name", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                { field: 'category', title: "Kategori", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                { title: "Check All", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" }, template:this.columnButton},
                { field: 'rperms', title: "Read", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: '<input type="checkbox" #= rperms ? \'checked="checked"\' : "" # class="k-checkbox k-checkbox-md k-rounded-md" />'
                },
                { field: 'cperms', title: "Create", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: '<input type="checkbox" #= cperms ? \'checked="checked"\' : "" # class="k-checkbox k-checkbox-md k-rounded-md" />'
                },
                { field: 'uperms', title: "Update", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: '<input type="checkbox" #= uperms ? \'checked="checked"\' : "" # class="k-checkbox k-checkbox-md k-rounded-md" />'
                },
                { field: 'dperms', title: "Delete", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: '<input type="checkbox" #= dperms ? \'checked="checked"\' : "" # class="k-checkbox k-checkbox-md k-rounded-md" />'
                },
            ],
        }
    },
    mounted: function(){
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        var checkClick = this.$props.checkClick;

        GridElement.on("click", "#CheckButton", function (e){
            e.preventDefault();
            grid.dataItem(e.target.closest("tr")).rperms = true;
            grid.dataItem(e.target.closest("tr")).cperms = true;
            grid.dataItem(e.target.closest("tr")).uperms = true;
            grid.dataItem(e.target.closest("tr")).dperms = true;
            this.dataItem = grid.dataItem(e.target.closest("tr"));
            grid.saveChanges(); 

            checkClick(this.dataItem);
        })
    },
    methods: {
        savePermissionClick(roleId){
            var grid = this.$refs.grid.kendoWidget();
            grid.saveChanges();
        },
        columnButton(){
           return `
                <div class="btn-group">
                    <button type="button" class="btn btn-success btn-sm" id="CheckButton">
                        <i class="fa fa-check"></i> Check </span>
                    </button>
                </div>
            `
        },
    },
}
</script>

<style scoped>
</style>