import gql from 'graphql-tag';
import { globalfunc } from '../../../../shared/GlobalFunction.js';

class RoleService {
    readQuery(){
        var query = `query{
            GetRole{
                roleid
                rname
                role_access{
                  moduleid
                  mname
                  category
                  rperms
                  cperms
                  uperms
                  dperms
                }
              }
        }`;
        return query;
    }

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeleteRole(RID:$id){
                response
            }
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }

    async addQuery(variables){
        const query = gql`mutation($data:String!){
            CreateRole(rname:$data){
                response
            }
        }`
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }
    
    async editQuery(variables){
        const query = gql`mutation($id:Int!,$data:String!){
            UpdateRole(RID:$id,rname:$data){
                response
            }
        }`
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }
    
    readPermQuery(){
        var query = `query( $id:Int!) {
            GetRole (RID:$id) {
                roleid
                role_access {
                    roleid
                    rname
                    moduleid
                    mname
                    category
                    rperms
                    cperms
                    uperms
                    dperms
                }
            }
        }`;
        return query;
    }
    
    async updatePerm(variables){
        var query = gql`mutation($data:[NewPerm!]!){
            UpdatePerm(input:$data){
                response
            }
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }

    updatePermission(){
        var query = `mutation($permission:[NewPerm!]!){
            UpdatePerm(input:$permission){
                role_access{
                    roleid
                    moduleid
                    mname
                    rperms
                    cperms
                    uperms
                    dperms
                }
            }
        }`;
        return query;
    }

    getRoleDdl(){
        var query = `query($id:Int!){
            GetRole(RID:$id){
                roleid
                ddl_access{
                    roleid 
                    ddlname 
                    ddl 
                    isallowed
                }
              }
        }`;
        return query;
    }
    
    updateDdl(){
        var query = `mutation ($ddlAccess:[NewDDLAccess!]!) {
            UpdateDdlAccess (input:$ddlAccess) {
                ddlList{
                  ddl
                  ddlname
                  ddltype
                  allowed_role
                }
            }
        }`;
        return query;
    }

    async getRoleDashboard(roleid){
        const variables = {
            id : roleid
        }
        var query = gql`query($id:Int!){
            GetRole(RID:$id){
                rname
                role_dashboard{
                    created_at
                    last_update
                    roleid
                    rname
                    dashboard_id
                    dashboard_name
                }
            }
        }`;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data;
    }

    getRoleFlag(){
        var query = `query($id:Int!){
            GetRole(RID:$id){
                rname
                role_flag{
                    flag_name,
                    flag_id,
                    flag_status
                }
            }
        }`;
        return query;
    }

    async getRoleRegion(roleid){
        const variables = {
            id : roleid
        }
        var query = gql`query($id:Int!){
            GetRole(RID:$id){
                role_region{
                    roleid
                    region_id
                    region_code
                    region_name
                    created_at
                    last_update
                }
            }
        }`;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data;
    }

    async getRoleFlagById(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int!){
            GetRole(RID:$id){
                rname
                role_flag{
                    flag_name,
                    flag_id,
                    flag_status
                }
            }
        }`;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetRole[0].role_flag;
    }

    async updateRoleDashboard(variables){
        var query = gql`mutation($id:Int!, $data:[NewRoleDashboard!]!) {
            UpdateRoleDashboard(RoleID:$id, New:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async updateRoleRegion(variables){
        var query = gql`mutation($id:Int!, $data:[NewRoleRegion!]!) {
            UpdateRoleRegion(RoleID:$id, New:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async updateRoleFlag(variables){
        var query = gql`mutation($id:Int!, $data:[NewRoleFlag!]!) {
            UpdateRoleFlag(RoleID:$id, New:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    getMasterDashboardQuery(){
        var query = `query{
            GetMasterDashboard{
                created_at
                last_update
                dashboard_id
                dashboard_name
            }
        }`;
        return query;
    }

    getMasterRegionQuery(){
        var query = `query{
            GetRegion{
                id
                region_code
                region_name
            }
        }`;
        return query;
    }

    async getMasterFlagQuery(){
        var query = gql`query{
            GetFlagList{
                created_at
                last_update
                flag_id 
                flag_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        return result;
    }

    gridSelector(selected, checkall, data){
        if (checkall == 'true'){
            if(selected == ''){
                var newData = data;
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.dashboard_id]= el
                })
                for (let i = 0; i < selectedData.length; i++) {
                    delete hashMap[selectedData[i].toString()]
                }
                var newData = [];
                Object.keys(hashMap).forEach(el=>{
                    newData.push(hashMap[el])
                })
                return newData;
            }
        }else{
            if(selected == ''){
                var newData = [];
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.dashboard_id]= el
                })
                var newData = [];
                for (let i = 0; i < selectedData.length; i++) {
                    newData.push(hashMap[selectedData[i].toString()])
                }
                return newData;
            }
        }
    }

    gridRegionSelector(selected, checkall, data){
        if (checkall == 'true'){
            if(selected == ''){
                var newData = data;
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.region_id]= el
                })
                for (let i = 0; i < selectedData.length; i++) {
                    delete hashMap[selectedData[i].toString()]
                }
                var newData = [];
                Object.keys(hashMap).forEach(el=>{
                    newData.push(hashMap[el])
                })
                return newData;
            }
        }else{
            if(selected == ''){
                var newData = [];
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.region_id]= el
                })
                var newData = [];
                for (let i = 0; i < selectedData.length; i++) {
                    newData.push(hashMap[selectedData[i].toString()])
                }
                return newData;
            }
        }
    }

}


export default new RoleService();