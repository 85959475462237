<template>
    <CRow class="p-3">
        <div class="col-xs-6 text-light pb-3">
            <button id="add" class="btn btn-success"
                @click="addClick()">
                <i class="fa fa-plus"></i> Add
            </button>
        </div>

        <datasource ref="dashboardDataSource" :data="this.DashboardGridData" :page-size="10" :schema-model-fields="this.DashboardGridDataSchemaModel"/>

        <kendo-grid ref="grid"
            :data-source-ref="'dashboardDataSource'"
            :editable="true"
            :pageable="true"
            :resizable="true"
            >
            
            <kendo-grid-column  :field="'dashboard_id'"
                                :title="'Id '"
                                :width="200"
                                :hidden = "true"
                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

            <kendo-grid-column  :field="'dashboard_name'"
                                :title="'Nama Dashboard'"
                                :width="200"
                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

            <kendo-grid-column  :title="'&nbsp;'"
                                :attributes="{ class: 'k-text-center' }"
                                :command="['destroy']"
                                :width="200"
                                ></kendo-grid-column>

        </kendo-grid>
        <dashboard-form ref="dashboardForm" :saveDashboardDataGrid="saveDashboardDataGrid"/>
    </CRow> 
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import dashboardForm from '../Component/DashboardForm.vue';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import RoleServices from '../Script/RoleServices.js';

export default {
    name: 'DashboardGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'dashboard-form' : dashboardForm,
        'datasource' : DataSource,
    },
    props: ['RoleId'],
    data(){
        var roleid = this.$props.RoleId
        return{
            ModalTitle: '',
            roleid: roleid,
            DashboardData:[],
            DashboardGridData: [],
            DashboardGridDataSchemaModel: {
                dashboard_id : {type: "string", editable: false},
                dashboard_name : {type: "string", editable: false},
            },
        }
    },
    methods: {
        async dashboardGridReload(roleid){
            var data = await RoleServices.getRoleDashboard(roleid);  
            this.DashboardGridData = globalfunc.objectToArrayConverter(data.GetRole[0].role_dashboard, "RoleDashboard-Data");    
        },
        addClick(){
            this.$refs.dashboardForm.addClick();
        },
        saveDashboardClick(roleid){
            this.DashboardData = this.$refs.grid.kendoWidget().dataSource._data;

            var DashboardArray = [];
            if (this.DashboardData.length > 0) {
                for (let i = 0; i < this.DashboardData.length; i++) {
                    var detail = { 
                        roleid: roleid,
                        dashboard_id: this.DashboardData[i].dashboard_id
                    }
                    DashboardArray.push(detail)
                }                
            }

            const variables = {
                id : roleid,
                data : DashboardArray
            }

            RoleServices.updateRoleDashboard(variables);
        },
        saveDashboardDataGrid(data){
            var gridData = this.$refs.grid.kendoWidget().dataSource._data;
            var ItemData = globalfunc.objectToArrayConverter(gridData, "RoleDashboard-Data");
            ItemData = ItemData.concat(data);
            this.DashboardGridData  = Object.values(ItemData.reduce((a, c) => (a[`${c.dashboard_id}${c.dashboard_name}`] = c, a), {}));            
        },
    },
}

</script>

<style scoped>
</style>